import http from '@/plugins/http';

export async function isAuthenticated() {
  try {
    await http.get('/auth/check_privileges');
    return true;
  } catch (e) {
    return false;
  }
}
export async function hasBetaAccess() {
  try {
    await http.get('/auth/check_privileges/beta');
    return true;
  } catch (e) {
    return false;
  }
}
