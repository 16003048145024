import playgroundRoutes from '@/modules/Playground/router';
import traceRoutes from '@/modules/Traces/router';
import {
  createRouter,
  createWebHistory,
  type NavigationGuardWithThis,
  type RouteRecordRaw,
} from 'vue-router';
import { authManager, guardForCallbackPage } from './plugins/auth';
import { useUser } from './store';

export const routes: RouteRecordRaw[] = [
  // @ts-expect-error - this is a valid route
  {
    path: '/callback',
    name: 'LoginCallback',
    beforeEnter: async (to, from, next) => {
      await guardForCallbackPage(authManager, async () => {
        const user = useUser();
        await user.fetch();
        next({ name: 'App' });
      });
    },
  },
  {
    path: '/',
    name: 'App',
    meta: { requiresAuth: true },
    components: {
      layout: () => import('@/layouts/BaseLayout.vue'),
    },
    children: [...traceRoutes, ...playgroundRoutes],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    components: {
      layout: () => import('@/views/NotFound.vue'),
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const authGuard: NavigationGuardWithThis<undefined> = async (to, from, next) => {
  if (
    !(
      to.matched.some((record) => record.meta.requiresAuth) ||
      to.matched.some((record) => record.meta.requiresBeta)
    )
  ) {
    next();
    return;
  }

  const user = useUser();
  if (!user.user) {
    await user.fetch();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!user.user) {
      await authManager.signinRedirect();
      return;
    }
  }

  if (
    (to.matched.some((record) => record.meta.requiresAuth) && !user.hasAccess) ||
    (to.matched.some((record) => record.meta.requiresBeta) && !user.hasBetaAccess)
  ) {
    // YOU ARE NOT ALLOWED TO ACCESS STUDIO
    next({ name: 'NotFound' });
    return;
  }

  next();
};

router.beforeEach(authGuard);

export default router;
