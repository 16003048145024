import { User, UserManager } from 'oidc-client-ts';

export const guardForEntryPage = async (
  userManager: UserManager,
  callback: (user: User) => void
) => {
  const user = await userManager.getUser();
  if (user) {
    callback(user);
  } else {
    await userManager.signinRedirect();
  }
};

export const guardForCallbackPage = async (
  userManager: UserManager,
  callback: () => Promise<void>
) => {
  await userManager.signinCallback(window.location.href);
  await callback();
};
