<script setup lang="ts">
import { useColorMode } from '@vueuse/core';
import { onMounted } from 'vue';
import { useCodeTheme } from './composables/useCodeTheme';
import { AaSnackbarThread } from '@aleph-alpha/ds-components-vue';
import { useSnackbarStore } from './store';

const mode = useColorMode();
const setCodeTheme = useCodeTheme();
const store = useSnackbarStore();

onMounted(() => {
  setCodeTheme(mode.value);
});
</script>

<template>
  <router-view name="layout" />
  <aa-snackbar-thread :snackbars="store.snackbars" :remove-snackbar="store.removeSnackbar" />
</template>

<style scoped></style>
