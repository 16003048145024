import * as auth from '@/services/auth';
import { useSnackbar } from '@aleph-alpha/ds-components-vue';
import { User } from 'oidc-client-ts';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { authManager } from './plugins/auth';
import { addBearerToken } from './plugins/http';

export const useUser = defineStore('userStore', () => {
  const user = ref<User | null>(null);
  const privileges = ref<Set<string> | null>(null);

  async function fetch() {
    user.value = await authManager.getUser();
    if (user.value) {
      addBearerToken(user.value.token_type, user.value.access_token);
    }

    privileges.value = new Set();
    if (await auth.hasBetaAccess()) privileges.value.add('beta');
    if (await auth.isAuthenticated()) privileges.value.add('studio');
  }

  const hasBetaAccess = computed(() => privileges.value?.has('beta'));
  const hasAccess = computed(() => privileges.value?.has('studio'));

  return {
    user,
    fetch,
    privileges,
    hasAccess,
    hasBetaAccess,
  };
});

export const useSnackbarStore = defineStore('snackbarStore', useSnackbar);
