import { ref } from 'vue';

export function useCodeTheme() {
  const prismTwilightPath = new URL('@/prismjs/prism_twilight_with_plugins.css', import.meta.url)
    .href;
  const prismPath = new URL('@/prismjs/prism.css', import.meta.url).href;

  const existingLink =
    Array.from(document.head.getElementsByTagName('link')).find(
      (link) =>
        link.rel === 'stylesheet' && (link.href === prismPath || link.href === prismTwilightPath)
    ) ?? null;
  const themeLink = ref<HTMLLinkElement | null>(existingLink);

  const loadTheme = (href: string) => {
    if (themeLink.value) {
      themeLink.value.href = href;
    } else {
      const link = document.createElement('link');
      link.href = href;
      link.rel = 'stylesheet';
      document.head.appendChild(link);
      themeLink.value = link;
    }
  };

  const setCodeTheme = (themeDescription) => {
    const theme = themeDescription === 'light' ? prismPath : prismTwilightPath;
    loadTheme(theme);
  };

  return setCodeTheme;
}
