import { http as baseHttp, Strategies, type HttpError } from '@aleph-alpha/lib-http';

export const errorInterceptor = (error: HttpError<'ky'>) => {
  const { response } = error;

  if (response.status && response.statusText) {
    const customError = new Error(`HTTP Error: ${response.status} ${response.statusText}`);

    customError.name = 'HTTPError';
    customError.stack = error.stack;

    (customError as HttpError<'ky'>).response = response;

    return customError as HttpError<'ky'>;
  }
  return error;
};

const http = baseHttp(Strategies.ky, {
  baseURL: '',
});

http.addHooks({
  beforeError: [errorInterceptor],
});

export const addBearerToken = (type, token) => {
  http.updateConfig({
    headers: {
      Authorization: `${type} ${token}`,
    },
  });
};

export default http;
