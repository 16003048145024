import { createApp } from 'vue';
import DS from '@aleph-alpha/ds-components-vue';
import { createPinia } from 'pinia';
import '@unocss/reset/tailwind.css';
import 'uno.css';
import 'virtual:uno.css';
import App from './App.vue';
import 'prismjs';

import router from '@/router';

const pinia = createPinia();
//eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const app = createApp(App);

app.use(pinia);
// @ts-expect-error DS is a plugin, but it's not typed as such in the package.
app.use(DS);
app.use(router);
app.mount('#app');
