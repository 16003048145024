import { UserManager, type UserManagerSettings } from 'oidc-client-ts';

if (!import.meta.env.VITE_IAM_ISSUER) {
  throw new Error('VITE_IAM_ISSUER is not defined');
}

if (!import.meta.env.VITE_IAM_CLIENT_ID) {
  throw new Error('VITE_IAM_CLIENT_ID is not defined');
}

if (!import.meta.env.VITE_APP_URL) {
  throw new Error('VITE_APP_URL is not defined');
}

export const settings: UserManagerSettings = {
  scope: 'openid email profile',
  loadUserInfo: true,
  authority: import.meta.env.VITE_IAM_ISSUER,
  client_id: import.meta.env.VITE_IAM_CLIENT_ID,
  post_logout_redirect_uri: import.meta.env.VITE_APP_URL,
  redirect_uri: `${import.meta.env.VITE_APP_URL}/callback`,
};

export const authManager = new UserManager(settings);
